<template>
  <div>
    <v-card class="ma-0" :maxHeight="height - 24" :minHeight="minHeight">
      <v-card-title class="secondary white--text py-0" @click1="$parent.fitchData()">
        <slot name="title"></slot>
      </v-card-title>
      <v-divider />
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: 0.5,
        }"
        min-height="200"
        transition="fade-transition"
      >
        <a-loader v-if="isLoading" />
        <apexchart v-if="!isLoading" :type="type" :height="height - 32" :options="chartOptions" :series="series"></apexchart>
      </v-lazy>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    defaults: Object,
    model: { type: Object, default: () => {} },
    height: { type: Number, default: 0 },
    series: { type: Array, default: () => [] },
    chartOptions: { type: Object, default: () => {} },
    type: String,
    defaults: Object,
    isLoading: { type: Boolean, default: false },
  },
  components: {},
  data() {
    return {
      isActive: false,
      loaded: false,
    };
  },
  created() {},
  computed: {
    minHeight() {
      let h = this.height;
      if (this.$vuetify.breakpoint.name == "xs" && this?.data?.length == 0) {
      }
      return h;
    },
  },
  methods: {},
};
</script>
